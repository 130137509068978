import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        border-radius: ${theme.radius.xlarge};
        height: 100%;
        padding: ${theme.gap.small};
        box-shadow: ${theme.shadow.large};
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: ${theme.gap.medium};
        background-color: ${theme.palette.color.white.main};
        background-image: linear-gradient(to bottom, var(--tariff-background) 0%, rgba(255, 255, 255, 0) 100%);

        @media ${theme.breakpoints.lg.min} {
            padding: ${theme.gap.large};
        }
    `,
);
