import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    PREMIUM_SUBSCRIPTION_MONTHLY_PRICE,
    PREMIUM_SUBSCRIPTION_MONTHLY_PRICE_WITH_VAT,
    PREMIUM_SUBSCRIPTION_YEARLY_PRICE,
    PREMIUM_SUBSCRIPTION_YEARLY_PRICE_WITH_VAT,
} from '@app/common/src/constants/premium-subscription';
import { Wrapper, Value, Suffix, WithoutVat, WithVat } from './tariff-premium-price.styled';

type TariffPremiumPriceProps = {
    period: 'yearly' | 'monthly';
};

export const TariffPremiumPrice: React.FC<TariffPremiumPriceProps> = ({ period }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            {/* {period === 'monthly' && ( */}
            {/*    <Value crossed> */}
            {/*        {PREMIUM_SUBSCRIPTION_MONTHLY_PRICE_CROSSED}&nbsp;{t(`blocks.pricing.common.withoutVat`)} */}
            {/*    </Value> */}
            {/* )} */}
            {/* {period === 'yearly' && ( */}
            {/*    <Value crossed> */}
            {/*        {PREMIUM_SUBSCRIPTION_YEARLY_PRICE_CROSSED}&nbsp;{t(`blocks.pricing.common.withoutVat`)} */}
            {/*    </Value> */}
            {/* )} */}
            <WithoutVat>
                <Value>
                    {period === 'monthly' ? PREMIUM_SUBSCRIPTION_MONTHLY_PRICE : PREMIUM_SUBSCRIPTION_YEARLY_PRICE}
                </Value>
                <Suffix>
                    &nbsp;{t(`blocks.pricing.common.withoutVat`)}&nbsp;/ {t(`blocks.pricing.common.${period}`)}
                </Suffix>
            </WithoutVat>
            <WithVat>
                <Value>
                    {period === 'monthly'
                        ? PREMIUM_SUBSCRIPTION_MONTHLY_PRICE_WITH_VAT
                        : PREMIUM_SUBSCRIPTION_YEARLY_PRICE_WITH_VAT}
                </Value>
                <Suffix>
                    &nbsp;{t(`blocks.pricing.common.withVat`)}&nbsp;/ {t(`blocks.pricing.common.${period}`)}
                </Suffix>
            </WithVat>
        </Wrapper>
    );
};
