export const getFakeString = (input: string) => {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    let fakeString = '';
    let firstWord = true;
    let firstLetter = true;

    for (let i = 0; i < input.length; i += 1) {
        if (input[i] === ' ') {
            fakeString += ' ';
            firstWord = false; // První slovo skončilo, zbytek bude malými písmeny
        } else if (/[a-zA-Z]/.test(input[i])) {
            const randomIndex = Math.floor(Math.random() * letters.length);
            if (firstWord && firstLetter) {
                fakeString += letters[randomIndex].toUpperCase();
                firstLetter = false;
            } else {
                fakeString += letters[randomIndex];
            }
        } else if (/[0-9]/.test(input[i])) {
            const randomIndex = Math.floor(Math.random() * numbers.length);
            fakeString += numbers[randomIndex];
        } else {
            fakeString += input[i]; // zachovává neznámé znaky
        }
    }

    return fakeString;
};
