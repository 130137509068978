import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    flex: 1;
    width: 100%;
    margin-bottom: 3rem;
`;
