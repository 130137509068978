import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    min-height: 3.8rem;
    width: 100%;
`;

export const Name = styled.div(
    ({ theme }) => css`
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.6rem;

        @media ${theme.breakpoints.lg.min} {
            font-size: 2rem;
            line-height: 2rem;
        }
    `,
);

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: var(--tariff-color-light);
    display: inline-block;
    margin: 0 1.6rem;
`;
