import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveBackground, SwitchInner, Option, Wrapper, Switch } from './pricing-switch-payment.styled';
import { PricingSwitchPaymentDiscount } from './discount/pricing-switch-payment-discount';

type PricingSwitchPaymentProps = {
    inverse?: boolean;
    discountPosition?: 'top' | 'bottom';
    period: 'yearly' | 'monthly';
    onPeriodChange: (period: 'yearly' | 'monthly') => void;
};

export const PricingSwitchPayment: React.FC<PricingSwitchPaymentProps> = ({
    onPeriodChange,
    period = 'yearly',
    inverse = false,
    discountPosition = 'bottom',
}) => {
    const { t } = useTranslation();

    const [activeWidth, setActiveWidth] = useState(165);
    const [activeLeft, setActiveLeft] = useState(181);
    const monthlyRef = useRef<HTMLDivElement>(null);
    const yearlyRef = useRef<HTMLDivElement>(null);

    const recalculatingBackgroundPosition = (newPeriod: 'yearly' | 'monthly') => {
        if (newPeriod === 'monthly' && monthlyRef.current) {
            setActiveWidth(monthlyRef.current.offsetWidth);
            setActiveLeft(monthlyRef.current.offsetLeft);
        } else if (newPeriod === 'yearly' && yearlyRef.current) {
            setActiveWidth(yearlyRef.current.offsetWidth);
            setActiveLeft(yearlyRef.current.offsetLeft);
        }
    };

    const handlePeriodChange = (newPeriod: 'yearly' | 'monthly') => {
        onPeriodChange(newPeriod);
        recalculatingBackgroundPosition(newPeriod);
    };

    return (
        <Wrapper>
            <Switch>
                <SwitchInner>
                    <ActiveBackground width={activeWidth} left={activeLeft} period={period} />
                    <Option
                        ref={monthlyRef}
                        active={period === 'monthly'}
                        onClick={() => handlePeriodChange('monthly')}
                    >
                        {t('blocks.pricing.common.paymentMonthly')}
                    </Option>
                    <Option ref={yearlyRef} active={period === 'yearly'} onClick={() => handlePeriodChange('yearly')}>
                        {t('blocks.pricing.common.paymentYearly')}
                        <PricingSwitchPaymentDiscount
                            position={discountPosition}
                            inverse={inverse}
                            isActive={period === 'yearly'}
                        />
                    </Option>
                </SwitchInner>
            </Switch>
        </Wrapper>
    );
};
