import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper, Name, Line } from './tariff-header.styled';

interface TariffHeaderProps {
    name: string;
    badge?: React.ReactNode;
}

export const TariffHeader: React.FC<TariffHeaderProps> = observer(({ name, badge }) => {
    return (
        <Wrapper>
            <Name>{name}</Name>
            <Line />
            {badge && badge}
        </Wrapper>
    );
});
