import React from 'react';
import { Wrapper, Arrow, FreeMonths, Discount } from './pricing-switch-payment-discount.styled';
import { getOpacity } from '../../../../../theme/utils/get-rgb';

type PricingSwitchPaymentDiscountProps = {
    isActive: boolean;
    inverse: boolean;
    position: 'top' | 'bottom';
};

export const PricingSwitchPaymentDiscount: React.FC<PricingSwitchPaymentDiscountProps> = ({
    isActive,
    inverse,
    position,
}: PricingSwitchPaymentDiscountProps) => {
    return (
        <Wrapper position={position} inverse={inverse} isActive={isActive}>
            <Arrow
                position={position}
                width="46"
                height="54"
                viewBox="0 0 46 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M38.2291 1.58427C38.6275 0.672522 39.9136 0.77151 40.5156 1.36588C42.6152 3.40265 44.5139 5.83288 45.7103 8.54656C45.8566 8.87962 45.9458 9.40183 45.6809 9.7347C45.5484 9.90113 45.3932 10.1432 45.1705 10.1999C45.0799 10.2276 45.0056 10.2465 44.9478 10.2565C44.5515 10.3434 44.0878 10.2448 43.7793 9.90407C43.0459 9.06368 42.3616 8.19685 41.6396 7.31862C43.2797 17.9675 38.1017 29.4078 31.3033 37.2223C27.573 41.5457 22.9768 45.195 18.0584 48.0042C15.5689 49.441 12.9972 50.6052 10.3466 51.6219C7.57148 52.6422 4.536 53.7079 1.58356 53.2713C0.607021 53.1006 0.225294 51.9122 1.132 51.3602C3.34967 50.0066 5.91243 49.4175 8.3552 48.5445C10.9339 47.63 13.4303 46.443 15.7987 45.1349C20.4225 42.4845 24.5539 39.149 28.2078 35.2154C32.0546 31.051 35.3039 26.0047 37.3619 20.6816C39.3138 15.5742 39.8552 10.3714 39.7181 4.96406C39.7181 4.96406 39.7055 4.96027 39.6804 4.95269C39.1267 6.10655 38.6107 7.27178 37.9063 8.38016C36.9255 9.85923 35.9323 11.7886 34.1245 12.1926C33.4187 12.3512 32.5563 11.8021 32.6338 11C32.8081 9.1951 34.2366 8.14002 35.2437 6.71015C36.3945 5.07601 37.357 3.38504 38.2291 1.58427Z"
                    fill={inverse ? getOpacity('#231D61', 0.7) : '#8E91AF'}
                />
            </Arrow>
            <FreeMonths>2 měsíce zdarma</FreeMonths> <br />o <Discount inverse={inverse}>16 % levnější!</Discount>
        </Wrapper>
    );
};
