import React from 'react';
import { Wrapper, Value } from './tariff-features-item.styled';
import { TariffFeaturesCheck } from '../check/tariff-features-check';
import { ValueString } from '../../../../app/value/string/value-string';

interface TariffFeaturesItemProps {
    value: string;
    locked: boolean;
}

export const TariffFeaturesItem: React.FC<TariffFeaturesItemProps> = ({ value, locked }) => {
    return (
        <Wrapper>
            <TariffFeaturesCheck />
            <Value>
                <ValueString text={value} mode={'pure'} locked={locked} />
            </Value>
        </Wrapper>
    );
};
