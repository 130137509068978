import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
`;

export const Value = styled.div(
    ({ theme }) => css`
        font-weight: 700;
        font-size: ${theme.text.size.vlarge.fontSize};
    `,
);
