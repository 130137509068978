import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        --tariff-color: ${theme.palette.color.default.dark};
        --tariff-color-light: ${theme.palette.color.default.opacity.medium};
        --tariff-background: ${theme.palette.color.default.opacity.high};
        --tariff-border: ${theme.palette.color.default.opacity.medium};

        border-radius: 2.4rem;
        width: 100%;
        color: var(--tariff-color);
        background-color: ${theme.palette.color.white.main};

        @media ${theme.breakpoints.lg.min} {
            max-width: 42rem;
        }
    `,
);
