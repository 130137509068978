import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './tariff-wrapper.styled';

interface TariffWrapperProps {
    children: React.ReactNode;
}

export const TariffWrapper: React.FC<TariffWrapperProps> = observer(({ children }) => {
    return <Wrapper>{children}</Wrapper>;
});
