import React from 'react';
import { Wrapper } from './tariff-features.styled';
import { TariffFeaturesItem } from './item/tariff-features-item';

interface TariffFeaturesProps {
    data: Array<string>;
    locked?: boolean;
}

export const TariffFeatures: React.FC<TariffFeaturesProps> = ({ data, locked = false }) => {
    return (
        <Wrapper>
            {data.map((item, index) => (
                <TariffFeaturesItem key={index} value={item} locked={locked} />
            ))}
        </Wrapper>
    );
};
