import React from 'react';
import { Wrapper } from './tariff-features-check.styled';
import { IconCheck } from '../../../../app/icons/check';

export const TariffFeaturesCheck: React.FC = () => {
    return (
        <Wrapper>
            <IconCheck />
        </Wrapper>
    );
};
