import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        margin-bottom: 10rem;

        @media ${theme.breakpoints.md.min} {
            margin-bottom: 14rem;
        }
    `}
`;

export const Switch = styled.div`
    ${({ theme }) => css`
        display: inline-flex;
        border: 0.1rem solid ${theme.palette.color.secondary.opacity.high};
        border-radius: 5rem;
        padding: 0.5rem;
        background-color: ${theme.palette.color.white.main};
        box-shadow: ${theme.shadow.large};
        transform: scale(0.7);

        @media ${theme.breakpoints.sm.min} {
            transform: scale(0.8);
        }

        @media ${theme.breakpoints.md.min} {
            transform: unset;
        }
    `}
`;

export const SwitchInner = styled.div`
    display: flex;
    position: relative;
    border-radius: 5rem;
`;

export const ActiveBackground = styled.div.withConfig({
    shouldForwardProp: (prop) => !['width', 'left', 'period'].includes(prop),
})<{
    width: number;
    left: number;
    period: 'monthly' | 'yearly';
}>`
    ${({ theme, left, width, period }) => css`
        position: absolute;
        height: 100%;
        background: ${period === 'monthly' ? theme.palette.color.primary.main : theme.palette.color.positive.main};
        border-radius: 5rem;
        left: ${left}px;
        width: ${width}px;
        will-change: left, width;
        transition: left 0.5s cubic-bezier(0.3, -0.3, 0.3, 1.3), width 0.5s cubic-bezier(0.3, -0.3, 0.3, 1.3),
            background 0.5s ease-in-out;
    `}
`;

export const Option = styled.div.withConfig({
    shouldForwardProp: (prop) => !['active'].includes(prop),
})<{
    active: boolean;
}>`
    ${({ theme, active }) => css`
        flex: 1;
        padding: 1.6rem 3rem;
        text-align: center;
        cursor: pointer;
        color: ${theme.palette.color.gray.main};
        position: relative;
        transition: color 0.3s ease;
        font-size: 1.8rem;
        font-weight: 700;
        white-space: nowrap;
        transition: color 0.5s ease-in-out;

        ${active &&
        css`
            color: ${theme.palette.color.white.main};
        `}
    `}
`;
