import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../../theme/utils/get-rgb';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive', 'inverse', 'position'].includes(prop),
})<{
    isActive: boolean;
    inverse: boolean;
    position: 'top' | 'bottom';
}>`
    ${({ theme, isActive, inverse, position }) => css`
        position: absolute;
        right: 13rem;
        color: ${inverse ? getOpacity(theme.palette.color.white.main, 0.9) : theme.palette.color.secondary.main};
        text-align: right;
        font-size: ${theme.text.size.large.fontSize};
        line-height: ${theme.text.size.large.lineHeight};
        transition: all ${theme.base.transition};

        ${position === 'bottom' &&
        css`
            top: calc(100% + 3.5rem);
            animation: bounce 2s infinite;
        `}

        ${position === 'top' &&
        css`
            bottom: calc(100% + 3.5rem);
            animation: bounceOpposite 2s infinite;
        `}

        ${isActive &&
        css`
            animation: unset;

            ${position === 'bottom' &&
            css`
                top: calc(100% + 5.8rem);
            `}

            ${position === 'top' &&
            css`
                bottom: calc(100% + 5.8rem);
            `}
        `}

        @keyframes bounce {
            0%,
            20%,
            50%,
            80%,
            100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(3rem);
            }
            60% {
                transform: translateY(1.5rem);
            }
        }

        @keyframes bounceOpposite {
            0%,
            20%,
            50%,
            80%,
            100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-3rem);
            }
            60% {
                transform: translateY(-1.5rem);
            }
        }
    `}
`;

export const Arrow = styled.svg.withConfig({
    shouldForwardProp: (prop) => !['position'].includes(prop),
})<{
    position: 'top' | 'bottom';
}>`
    ${({ position }) => css`
        position: absolute;
        left: calc(100% + 1rem);

        ${position === 'bottom' &&
        css`
            bottom: calc(100% - 1rem);
        `}

        ${position === 'top' &&
        css`
            top: calc(100% - 1rem);
            transform: rotate(180deg) scale(-1, 1);
        `}
    `}
`;

export const FreeMonths = styled.strong`
    color: inherit;
`;

export const Discount = styled.strong.withConfig({
    shouldForwardProp: (prop) => !['inverse'].includes(prop),
})<{
    inverse: boolean;
}>`
    ${({ theme, inverse }) => css`
        color: ${inverse ? theme.palette.color.white.main : theme.palette.color.positive.main};
        font-weight: 900;
    `}
`;
