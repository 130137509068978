import React, { useEffect, useRef, useState } from 'react';
import { FontWeightEnum, TextVariantColorEnum } from '@app/common';
import { Locked, Wrapper } from './value-string.styled';
import { getFakeString } from '../../../../utils/get-fake-string';

type ValueStringCommonProps = {
    mode?: 'styled' | 'pure';
    text: string;
    locked?: boolean;
    inParentheses?: boolean;
};

type ValueStringStyledProps = {
    mode: 'styled';
    fontWeight?: FontWeightEnum;
    color?: TextVariantColorEnum;
};

type ValueStringPureProps = {
    mode?: 'pure';
};

type ValueString = ValueStringCommonProps & (ValueStringStyledProps | ValueStringPureProps);

export const ValueString: React.FC<ValueString> = (props) => {
    const lockedRef = useRef<HTMLDivElement>(null);
    const [blur, setBlur] = useState<number>(0.3);
    const [value, setValue] = useState<string>('');

    const { text, locked = false, inParentheses = false } = props;

    const mode = props.mode ?? 'styled';

    useEffect(() => {
        const fakeText = getFakeString(text);
        const valueBefore = locked ? fakeText : text;
        setValue(inParentheses ? `(${valueBefore})` : valueBefore);

        if (lockedRef.current) {
            const fontSizeValue = parseFloat(window.getComputedStyle(lockedRef.current).fontSize);
            const blurSize = fontSizeValue / 50;
            const roundedBlurSize = parseFloat(blurSize.toFixed(2));
            setBlur(roundedBlurSize);
        }
    }, []);

    if (mode === 'styled') {
        const { color = TextVariantColorEnum.Default, fontWeight = FontWeightEnum.Medium } =
            props as ValueStringStyledProps;

        return (
            <Wrapper variant={color} fontWeight={fontWeight}>
                <Locked ref={lockedRef} locked={locked} blur={blur}>
                    {value}
                </Locked>
            </Wrapper>
        );
    }

    return locked ? (
        <Locked ref={lockedRef} blur={blur} locked>
            {value}
        </Locked>
    ) : (
        <>{value}</>
    );
};
