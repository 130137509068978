import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconCheck: React.FC = () => (
    <Icon viewBox="0 0 10 7" fill="none">
        <path
            d="M1.5 3.5 4 6l4.5-4.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
