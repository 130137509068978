import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tariff-color-light);
    color: var(--tariff-color);

    i {
        width: 1.2rem;
        height: 1.2rem;
    }
`;
